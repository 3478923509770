<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CamelCasePrice',
  props: {
    // 是否使用驼峰样式
    showCamelPrice: {
      type: Boolean,
      default: false,
    },
    // 原始价格字符串
    originString: {
      type: String,
      default: '',
    },
    // 币种符号前缀
    currencySymbolPrefix: {
      type: String,
      default: ''
    },
    // 币种符号后缀
    currencySymbolSuffix: {
      type: String,
      default: ''
    },
    // 小数点前金额
    numberBeforeDot: {
      type: [String, Number],
      default: ''
    },
    // 小数点后金额
    numberAfterDot: {
      type: String,
      default: ''
    },
    // 驼峰价格点前后fontSize
    camelPriceStyle: {
      type: Object,
      default: () => {}
    },
    // 来源组件
    compSrc: {
      type: String,
      default: ''
    }
  },
  render({ $props: props, $data: data }) {
    const { currencySymbolPrefix, numberBeforeDot, numberAfterDot, currencySymbolSuffix, showCamelPrice, originString, camelPriceStyle = {}, compSrc } = props || {}
    const compSrcFlag = compSrc ? `fsp__${compSrc}` : ''
    const camelHtml = (
      <span class="camel-case-price" {...data} style={camelPriceStyle?.after}>
        <span class={`camel-case-price__prefix-symbol ${compSrcFlag}`}>
          {currencySymbolPrefix}
        </span>
        <span class={`camel-case-price__number-before-dot ${compSrcFlag}`} style={camelPriceStyle?.before}>
          {numberBeforeDot}
        </span>
        <span class={`camel-case-price__number-after-dot ${compSrcFlag}`}>
          {numberAfterDot}
        </span>
        <span class={`camel-case-price__suffix-symbol ${compSrcFlag}`}>
          {currencySymbolSuffix}
        </span>
      </span>
    )
    
    const notCamelPrice = (
      <span {...data}>
        {originString}
      </span>
    )
    const result = showCamelPrice ? camelHtml : notCamelPrice
    return result
  },
})
</script>

<style lang="less">
.camel-case-price {
  height: 0.64rem;
  &__number-before-dot {
    font-size: 20 / 37.5rem;
  }
}
// .not-camel-case-price {
//   padding-top: .03rem;  // 为了解决部分机型折扣标签掉下来没有完全隐藏的问题
// }
</style>
