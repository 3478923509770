<script lang="jsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NormalGoodsLabel',
  props: {
    labelText: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  render({ $props: props, $data: data }) {
    const { labelText, type } = props
    return (
      <div
        class={[
          {
            'product-item__normal-label': true,
            'product-item__newflash-label': type == 'newFlash',
            'product-item__localseller-label': type == 'localSeller',
            'product-item__quickship-label': type == 'quickShip',
            'product-item__pricecut-label': type == 'priceCut',
            'product-item__promotion-label': type == 'promotion',
            'product-item__sellingpoint-label': type == 'sellingpoint',
            'product-item__sellingpoint-label-new': type == 'newSellingpoint',
            'product-item__ccctsp-label': type == 'cccTspLabel',
            'product-item__stockleft-label': type == 'stockleft',
            'product-item__userAct-label': type == 'userAct',
          }, 
          data.class,
          data.staticClass
        ]}
        style={{
          ...data.style, 
          ...data.staticStyle
        }}
      >
        { labelText }
      </div>
    )
  },
})
</script>

<style lang="less">
.product-item {
  &__normal-label {
    .font-dpr(20px);
    display: inline-block;
    height: 0.4267rem;
    line-height: 0.4267rem;
    white-space: nowrap;
    padding: 0 0.1067rem;
    margin-top: 0.1067rem;
  }
  &__newflash-label {
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
  }
  &__localseller-label,
  &__quickship-label {
    .text-overflow();
    flex-shrink: 0;
    max-width: 100%;
    color: @sui_color_safety;
    background: @sui_color_safety_bg;
  }
  &__pricecut-label {
    color: @sui_color_highlight;
  }
  &__promotion-label {
    .text-overflow();
    flex-shrink: 0;
    max-width: 100%;
    color: @sui_color_promo_dark;
    background: @sui_color_promo_bg;
  }
  &__sellingpoint-label {
    color: @sui_color_micro_emphasis;
    background-color: @sui_color_micro_emphasis_bg;
  }
  &__sellingpoint-label-new {
    color: @sui_color_gray_dark2;
    background-color: @sui_color_gray_weak2;
  }
  &__stockleft-label {
    color: @sui_color_highlight;
  }
  &__userAct-label {
    .text-overflow();
    color: @sui_color_micro_emphasis;
    background: #fff;
    padding-left: 0;
    margin-top: 0.1067rem;
  }
}
</style>
